import { Dialog, DialogContent } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import { usePreventScroll } from "@react-aria/overlays";
import type { DeepNullableObj, EarlyRehabAssessment } from "core/types";
import CheckboxInputField, {
  CheckboxInputFieldPresenter,
} from "ds_legacy/components/CheckboxInputField/index";
import DatePickerInputField from "ds_legacy/components/DatePickerInputField";
import { GREY_100 } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_FAMILY,
  FONT_SIZE_14,
  FONT_WEIGHT_MEDIUM,
  LINE_HEIGHT_18,
  LINE_HEIGHT_40,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { useCallback, useMemo } from "react";
import {
  convertModelDefinition,
  SimpleFormRenderProps,
} from "react-forms-state";
import { ObservableValue } from "react-forms-state/src/Context";
import { Observable } from "rxjs";
import styled from "styled-components";
import { useTranslations } from "translations";
import { convertToNumberValueDef, dateValueDef } from "../formDefinition";
import {
  AssessmentActions,
  calculateScore,
  DialogHeader,
  TotalScore,
  useGetFormValues,
} from "../shared";

export type EarlyRehabAssessmentModalProps = {
  initialValues?: Partial<Omit<EarlyRehabAssessment, "score">>;
  isOpen?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: EarlyRehabAssessment) => void;
};

const BG_COLOR = GREY_100;

const defaultValues: DeepNullableObj<Omit<EarlyRehabAssessment, "score">> = {
  date: null,
  form: {
    behavioural_disorder: null,
    intensive_medical_monitoring: null,
    intermittent_ventilation: null,
    orientation_disorder: null,
    severe_communication_disorder: null,
    supervised_swallowing_disorder: null,
    tracheostoma_requiring_aspiration: null,
  },
};

const getCheckboxLabelStyle = (): CSSProperties => ({
  width: "100%",
  margin: 0,

  "span.MuiCheckbox-root": {
    height: LINE_HEIGHT_40,
  },

  ".MuiFormControlLabel-label": {
    padding: padding(0, 0, 0, 1),
  },
});

const StyledCheckboxWrapper = styled.div<{ isPrint: boolean }>`
  background: ${({ isPrint }) => (isPrint ? "" : BG_COLOR)};

  border-radius: ${dp(8)};
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;

  margin: ${margin(1, 0, 1, 0)};

  font-family: ${FONT_FAMILY};
`;

function isChecked(value: number | undefined) {
  return typeof value === "number" && value !== 0;
}

function useEarlyRehabAssessmentConfig(
  initialValues: EarlyRehabAssessment["form"] | undefined,
) {
  const translations = useTranslations();
  const config: {
    checked: boolean;
    elementName: keyof NonNullable<EarlyRehabAssessment["form"]>;
    label: string;
    value: number;
  }[] = useMemo(
    () => [
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex
            .intensiveMonitoring,
        elementName: "intensive_medical_monitoring",
        checked: isChecked(initialValues?.intensive_medical_monitoring),
        value: -50,
      },
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex.tracheostoma,
        elementName: "tracheostoma_requiring_aspiration",
        checked: isChecked(initialValues?.tracheostoma_requiring_aspiration),
        value: -50,
      },
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex
            .intermittentVentilation,
        elementName: "intermittent_ventilation",
        checked: isChecked(initialValues?.intermittent_ventilation),
        value: -50,
      },
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex
            .orientationConfusion,
        elementName: "orientation_disorder",
        checked: isChecked(initialValues?.orientation_disorder),
        value: -50,
      },
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex
            .behaviouralDisorder,
        elementName: "behavioural_disorder",
        checked: isChecked(initialValues?.behavioural_disorder),
        value: -50,
      },
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex
            .communicationDisorder,
        elementName: "severe_communication_disorder",
        checked: isChecked(initialValues?.severe_communication_disorder),
        value: -25,
      },
      {
        label:
          translations.patient.medicalDiagnosis.earlyRehabIndex
            .swallowingDisorder,
        elementName: "supervised_swallowing_disorder",
        checked: isChecked(initialValues?.supervised_swallowing_disorder),
        value: -50,
      },
    ],
    [initialValues],
  );

  return config;
}

export function EarlyRehabAssessmentContent({
  initialFormValue,
  isViewMode,
  onChange,
  valueChangeObs,
}: {
  initialFormValue?: EarlyRehabAssessment;
  isViewMode?: boolean;
  onChange?: (value: any, statePath: string, validation: any) => void;
  valueChangeObs?: Observable<ObservableValue>;
}) {
  const isPrint = usePrint();
  const config = useEarlyRehabAssessmentConfig(initialFormValue?.form);
  const translations = useTranslations();

  let formValue = useGetFormValues(valueChangeObs);
  if (isViewMode && initialFormValue) {
    formValue = initialFormValue.form;
  }

  return (
    <VerticalLayout width="100%" overflow="visible" gap={padding(2)}>
      <VerticalLayout width="100%" overflow="visible">
        {!isViewMode && (
          <Body
            style={{ margin: 0 }}
            fontSize={FONT_SIZE_14}
            fontWeight={FONT_WEIGHT_MEDIUM}
            lineHeight={LINE_HEIGHT_18}
          >
            {translations.patient.medicalDiagnosis.earlyRehabIndex.subtitle}
          </Body>
        )}
        {!isPrint && !isViewMode && <AsteriskExplained />}
      </VerticalLayout>
      <div>
        <DatePickerInputField
          disabled={isViewMode && !isPrint}
          disableFuture
          elementName="date"
          hasCustomValidation
          label={translations.patient.medicalDiagnosis.assessmentDate}
          onChange={(value) => onChange?.(value as any, "date", true)}
          placeholder={translations.patient.medicalDiagnosis.assessmentDate}
          required={!isPrint && !isViewMode}
          value={isViewMode ? initialFormValue?.date : undefined}
        />
      </div>
      <StyledCheckboxWrapper isPrint={isPrint}>
        {config.map(({ checked, elementName, label, value }) => (
          <div key={elementName}>
            {isViewMode ? (
              <CheckboxInputFieldPresenter
                label={label}
                elementName={`form.${elementName}`}
                customValue={Number(value).toString()}
                required={false}
                labelStyleOverride={getCheckboxLabelStyle()}
                checked={checked}
                disabled={!isPrint}
              />
            ) : (
              <CheckboxInputField
                label={label}
                elementName={`form.${elementName}`}
                customValue={Number(value).toString()}
                required={false}
                labelStyleOverride={getCheckboxLabelStyle()}
              />
            )}
          </div>
        ))}
      </StyledCheckboxWrapper>
      <HorizontalLayout justify="flex-end">
        <TotalScore score={calculateScore(formValue)} />
      </HorizontalLayout>
    </VerticalLayout>
  );
}

export function EarlyRehabAssessmentModal({
  initialValues = {},
  isOpen,
  onCancel,
  onSubmit,
}: EarlyRehabAssessmentModalProps) {
  usePreventScroll({ isDisabled: !isOpen });
  const translations = useTranslations();
  const { isTablet } = useMedia();

  const handleSubmit = useCallback(
    (values: Omit<EarlyRehabAssessment, "score">) => {
      onSubmit?.({
        ...values,
        score: calculateScore(values.form),
      });
    },
    [onCancel, onSubmit, calculateScore],
  );

  return (
    <Dialog
      fullScreen={isTablet}
      maxWidth={false}
      onClose={onCancel}
      open={!!isOpen}
      PaperProps={{
        "aria-modal": "true",
        sx: { maxWidth: dp(720), width: "100%" },
      }}
    >
      <DialogHeader
        title={translations.patient.medicalDiagnosis.earlyRehabForm}
        onCancel={onCancel}
      />
      <SimpleFormRenderProps
        asHtmlForm
        onSubmit={handleSubmit}
        formStyle={{
          boxSizing: "border-box",
          display: "unset",
          padding: padding(0, 2, 2, 0),
        }}
        formInputValue={
          initialValues.form && initialValues.date
            ? initialValues
            : defaultValues
        }
        modelDefinition={convertModelDefinition({
          ...dateValueDef("date"),
          ...convertToNumberValueDef("form.intensive_medical_monitoring"),
          ...convertToNumberValueDef("form.tracheostoma_requiring_aspiration"),
          ...convertToNumberValueDef("form.intermittent_ventilation"),
          ...convertToNumberValueDef("form.orientation_disorder"),
          ...convertToNumberValueDef("form.behavioural_disorder"),
          ...convertToNumberValueDef("form.severe_communication_disorder"),
          ...convertToNumberValueDef("form.supervised_swallowing_disorder"),
        })}
      >
        {({ onChange, submit, valueChangeObs }) => {
          return (
            <>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <EarlyRehabAssessmentContent
                  onChange={onChange}
                  valueChangeObs={valueChangeObs}
                />
              </DialogContent>
              <AssessmentActions
                defaultValues={defaultValues}
                onCancel={onCancel}
                onChange={onChange}
                submit={submit}
              />
            </>
          );
        }}
      </SimpleFormRenderProps>
    </Dialog>
  );
}
